<template>
  <div>
    <entity-header
      class="mb-10"
      :title="unit.name"
      :is-archived="unit.isArchived"
      :back-route="backRoute"
      :edit-route="editRoute"
      :can-update="canUpdateProjects"
      @archive="archive"
    />

    <div v-if="isLoading" class="d-flex justify-center primary--text">
      <v-progress-circular indeterminate />
    </div>
    <div v-else class="units-tabs">
      <view-builder
        :schema-list="[
          $options.detailsSchema,
          $options.addressSchema,
          $options.areasSchema,
          $options.landlordInfoSchema,
        ]"
        :data="unit"
        is-tabs
        :tabs="tabs"
        :active-tab="tab"
        @change-tab="changeTab"
      />
    </div>
  </div>
</template>

<script>
// Services
import realtyService from '@/services/realty';
import { normalizeUnit } from '@/services/serializations';
import analyticsService from '@/services/analytics';
import ViewBuilderService from '@/services/viewBuilder';

// Constants
import { PROJECTS_ARCHIVE, PROJECTS, UNITS_EDIT } from '@/constants/routes';
import { TAB_DETAILS, TAB_ADDRESS, TAB_AREAS, TAB_LANDLORD_INFO } from '@/constants/tabs';
import { UNIT_VIEWING, ARCHIVE_UNIT, UNARCHIVE_UNIT } from '@/constants/analyticsActions';
import { PROJECTS as PROJECTS_SUBJECT } from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';

import ViewBuilder from '@/components/schema/ViewBuilder.vue';
import EntityHeader from '@/components/EntityHeader.vue';

// Schema
import { detailsSchema, addressSchema, areasSchema, landlordInfoSchema } from '@/schemas/unit.view.schema';

export default {
  unsubscribe: null,

  name: 'UnitsDetailed',

  components: { ViewBuilder, EntityHeader },

  data() {
    return {
      isLoading: false,
      unit: {},
      id: this.$route.params.id,
      tab: 0,
      tabs: [
        {
          label: 'tab.details',
          value: TAB_DETAILS,
        },
        {
          label: 'tab.address',
          value: TAB_ADDRESS,
        },
        {
          label: 'tab.areas',
          value: TAB_AREAS,
        },
      ],
    };
  },

  computed: {
    canUpdateProjects() {
      return this.$can(UPDATE, PROJECTS_SUBJECT);
    },

    backRoute() {
      const routeName = this.unit.isArchived ? PROJECTS_ARCHIVE : PROJECTS;

      return { name: routeName, query: { page: this.$route.query.prevPage || 1 } };
    },

    editRoute() {
      return { name: UNITS_EDIT, params: { id: this.id, schema: this.tabs[this.tab].value } };
    },
  },

  mounted() {
    analyticsService.track(UNIT_VIEWING);
    this.fetchUnit();

    this.$options.unsubscribe = ViewBuilderService.subscribe('archive-usage', () => this.fetchUnit());
  },

  beforeDestroy() {
    this.$options.unsubscribe();
  },

  methods: {
    archive() {
      analyticsService.track(this.isArchived ? UNARCHIVE_UNIT : ARCHIVE_UNIT);
      realtyService.archiveUnit(this.id, !this.unit.isArchived).then(() => {
        this.unit.isArchived = !this.unit.isArchived;
      });
    },

    fetchUnit() {
      this.isLoading = true;

      realtyService
        .getUnitById(this.$route.params.id)
        .then(unit => {
          this.unit = normalizeUnit(unit, true);
          if (this.unit.landlordInfo) {
            this.tabs.push({
              label: 'tab.landlord_info',
              value: TAB_LANDLORD_INFO,
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    changeTab(newTab) {
      this.tab = typeof newTab === 'number' ? newTab : this.tab + 1;
    },
  },

  detailsSchema,
  addressSchema,
  areasSchema,
  landlordInfoSchema,
};
</script>
